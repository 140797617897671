import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';



import {CurreLocalStorage
  } from '../../util/localStorage';
const initialState = {
  currencies: [
    {id:"1YiC1Sz9MkYPY3LG8MlN",name:"USD",nameAr:"USD",toUSD:1,toAED:3.636376859552217,toSAR:3.75},{id:"papAwH0XoKC3AmT9YmR7",name:"AED",nameAr:"AED",toUSD:0.274999,toAED:1,toSAR:1.0700108071091519},{id:"vIv6NVJ1rA6jwrsfoiXT",name:"SAR",nameAr:"SAR",toUSD:0.26666666666666666,toAED:0.93457,toSAR:1}
  ],
  selectedcurrency:"",
  currencydefault:"papAwH0XoKC3AmT9YmR7",
  countryCode:"AE",
  

};



const CurrencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    GetCurrency: (state, { payload }) => {
      state.currencies=payload;
    },
    SelectedCurrencies: (state, { payload }) => {
        state.selectedcurrency=payload;
        CurreLocalStorage(payload);
      },
      GetCurrencyDefault: (state, { payload }) => {
        state.currencydefault=payload;
       
      },

      GetCountryCode: (state, { payload }) => {
        state.countryCode=payload;
       
      },

},

});

export const { GetCurrency,SelectedCurrencies,GetCurrencyDefault,SetFlags,GetCountryCode} =
CurrencySlice.actions;

export default CurrencySlice.reducer;
